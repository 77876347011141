@use '../../styles/yr' as *;

.coast-page__tab-content {
  margin-top: size(4);
}

.coast-page__observation-cards {
  margin-top: size(3);

  @include mq-gt($mq-990) {
    margin-top: size(6);
  }
}

// The view switchers in the observation cards don't work in no-js mode,
// and the graph is non-interactive as well, so we hide the observation cards
// completely in no-js mode.
.no-js .coast-page__observation-cards {
  display: none;
}

.coast-page__card-list {
  margin-top: size(5);
}

.coast-page__external-services {
  margin-top: size(4);
}
