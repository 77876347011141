@use '../../styles/yr' as *;

.water-arrow {
  display: inline-block;
  position: relative;
  overflow: hidden;
  width: size(3);
  height: size(3);
}

.water-arrow__arrow {
  position: absolute;
  top: 50%;
  left: 50%;
}
