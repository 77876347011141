@use "sass:math";
@use '../../styles/yr' as *;

$mq-two-rows-grid: $mq-600;
$mq-one-row-grid: 1150px;

.external-services__items {
  display: grid;
  margin-bottom: size(2);

  grid-template-areas:
    'item-1'
    'item-2'
    'item-3'
    'item-4';
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  grid-gap: size(2);

  @include mq-gt($mq-two-rows-grid) {
    grid-template-areas:
      'item-1 item-2'
      'item-3 item-4';
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: size(2);
  }

  @include mq-gt($mq-one-row-grid) {
    grid-template-areas: 'item-1 item-2 item-3 item-4';
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    grid-gap: size(5);
  }
}
