@use '../../styles/yr' as *;
$hour-by-hour-icon: var(--color-stroke-subtle-gray);

$date-and-warnings-mobile-height: size(3);

.coast-forecast-daily-item {
  display: grid;
  position: relative;
  border-bottom: $divider-solid;
  background-color: var(--color-background-base);

  // We make sure the hover styles is only added when user agen supports it
  // or the user is using an external pointer of some sorts
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: var(--color-background-muted-core-blue);
    }
  }

  // touch screens should get a background-color when touching the list-item
  &:active {
    background-color: var(--color-background-muted-core-blue);
  }

  @include mq-gt($mq-990) {
    grid-template-columns: size(26) 2fr 2fr 1fr;
    grid-template-rows: rem(72px);
    grid-template-areas: 'date symbols forecast link';

    padding-left: size(2);
    padding-right: size(2);
  }

  @include mq-lte($mq-990) {
    grid-template-columns: 3fr 2fr 2fr size(3);
    grid-template-rows: $date-and-warnings-mobile-height rem(68px);
    row-gap: size(0.5);
    grid-template-areas:
      'date date date link'
      'forecast symbols symbols link';

    margin-bottom: size(0.5);
    box-shadow: $theme-box-shadow;

    &:last-child {
      margin-bottom: 0;
    }

    border-bottom: none;
    padding: rem(10px) size(1) rem(11px) size(2);
  }

  @include mq-lte($mq-600) {
    grid-template-columns: size(12) auto auto size(3);
  }
}

.coast-forecast-daily-item__date-and-warnings {
  display: flex;

  grid-area: date;
  align-self: center;
  white-space: nowrap;
}

.coast-forecast-daily-item__item-date {
  align-self: center;
  display: block;

  // Prevent the tap-highlight on li-element from making this invisible while tapping
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-decoration: none;

  &:after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    // Render in front of most of the content in the list item
    z-index: 1;
  }

  // We remove the default focus style from the link itself
  // and add it to the larger pseudo-element itself so the whole
  // list item gets a focus outline when the date link is focused.
  &:focus {
    outline: 0;

    &:after {
      @include focus-style;
    }
  }
}

.coast-forecast-daily-item__warnings {
  margin-left: size(2);
  align-self: center;
  display: flex;
  align-items: center;
}

.coast-forecast-daily-item__warnings-mobile {
  display: flex;
  align-items: center;
  margin-bottom: size(0.5);
  @include mq-gt($mq-990) {
    display: none;
  }
}

.coast-forecast-daily-item__warnings-desktop {
  display: flex;
  align-items: center;
  @include mq-lte($mq-990) {
    display: none;
  }
}

.coast-forecast-daily-item__symbols {
  display: grid;
  align-self: center;

  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-areas: 'symbol-0 symbol-1 symbol-2 symbol-3';
  grid-area: symbols;

  @include mq-lte($mq-990) {
    // To vertically center the symbols on the whole line we add a negative margin the
    // size of date-and warnings since that grid row is not part of this grid-area
    margin-top: -$date-and-warnings-mobile-height;
  }
}

.coast-forecast-daily-item__symbol {
  justify-self: center;
}

// We need to specify grid areas since IE does not support auto-layout
.coast-forecast-daily-item__symbol-0 {
  grid-area: symbol-0;
}
.coast-forecast-daily-item__symbol-1 {
  grid-area: symbol-1;
}
.coast-forecast-daily-item__symbol-2 {
  grid-area: symbol-2;
}
.coast-forecast-daily-item__symbol-3 {
  grid-area: symbol-3;
}

.coast-forecast-daily-item__forecast {
  grid-area: forecast;

  @include mq-gt($mq-990) {
    display: grid;
    align-self: center;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: 'wind wave-height temperature';
    column-gap: size(1);
  }

  @include mq-lte($mq-990) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
}

// We need to specify grid areas since IE does not support auto-layout
.coast-forecast-daily-item__wind {
  @include mq-gt($mq-990) {
    grid-area: wind;
    justify-self: end;
  }
}

.coast-forecast-daily-item__wave-height {
  @include mq-gt($mq-990) {
    grid-area: wave-height;
    justify-self: end;
  }
}

.coast-forecast-daily-item__temperature {
  @include mq-gt($mq-990) {
    grid-area: temperature;
    justify-self: end;
  }
}

.coast-forecast-daily-item__symbol,
.coast-forecast-daily-item__empty-symbol {
  position: relative;

  height: size(5);
  width: size(5);

  & > .weather-symbol {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    height: 100%;
    width: 100%;
  }
}

.coast-forecast-daily-item__wind,
.coast-forecast-daily-item__wave-height,
.coast-forecast-daily-item__temperature {
  position: relative;
  display: flex;
}

.coast-forecast-daily-item__icon-wrapper {
  @include mq-gt($mq-990) {
    display: none;
  }

  position: relative;
  margin-right: size(3);
}

.coast-forecast-daily-item__icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: var(--color-fill-subtle);

  @include mq-gt($mq-990) {
    display: none;
  }
}

.coast-forecast-daily-item__hour-by-hour {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  grid-area: link;
  align-self: center;
  justify-self: flex-end;

  text-decoration: none;
}

.coast-forecast-daily-item__hour-by-hour-icon {
  margin-left: size(1);
  color: $hour-by-hour-icon;
}

.coast-forecast-daily-item__hour-by-hour-text {
  @include mq-lte($mq-990) {
    display: none;
  }
}
