@use '../../styles/yr' as *;

.coast-table__table {
  margin: size(2) 0 size(5) 0;
}

.coast-table__symbol > .weather-symbol {
  height: size(4);
  width: size(4);
}
