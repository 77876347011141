@use '../../styles/yr' as *;

.wave {
  color: var(--color-text-base);
  display: inline-flex;
  white-space: pre-wrap;
}

.wave__arrow-icon {
  display: block;
}
