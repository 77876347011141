@use '../../styles/yr' as *;

.coast-graph__wind {
  color: var(--color-graph-wind-wind);
}

.coast-graph__hover-panel-wind {
  color: var(--color-text-weather-wind-alt);
}

.coast-graph__wave-height {
  color: var(--color-graph-wave-height-wave-height);
}

.coast-graph__sea-current {
  color: var(--color-graph-sea-current-sea-current);
}

.coast-graph__water-level-forecast {
  color: var(--color-graph-sea-level-forecast);
}

.coast-graph__water-level-prediction {
  color: var(--color-graph-sea-level-predicted);
}
