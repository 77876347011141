@use '../../styles/yr' as *;

$border: 1px;

.color-button {
  align-items: center;
  border-radius: rem(8px);
  padding: calc(size(2) - $border);
  border: 1px solid transparent;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  text-decoration: none;

  display: flex;
  gap: size(1);
  justify-content: center;
  overflow: hidden;
}

.color-button__item-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.color-button[data-tone='blue'] {
  color: var(--color-fill-darken-core-blue);
  background-color: var(--color-background-muted-core-blue);
  border-color: var(--color-stroke-muted-core-blue);

  &:hover {
    border-color: var(--color-stroke-accent-core-blue);
    background-color: var(--color-background-subtle-core-blue);
  }

  &:focus,
  &:focus-within {
    border-color: var(--color-stroke-active);
    box-shadow: 0 0 0 1px var(--color-stroke-active) inset;
  }

  @include active() {
    border-color: var(--color-background-subtle-core-blue);
    background-color: var(--color-background-subtle-core-blue);
  }
}

.color-button[data-tone='red'] {
  color: var(--color-text-darken-red);
  background-color: var(--color-background-muted-red);
  border-color: var(--color-stroke-subtle-red);

  &:hover {
    border-color: var(--color-stroke-accent-red);
    background-color: var(--color-background-subtle-red);
  }

  &:focus,
  &:focus-within {
    border-color: var(--color-stroke-active);
    box-shadow: 0 0 0 1px var(--color-stroke-active) inset;
  }

  @include active() {
    border-color: var(--color-background-subtle-red);
    background-color: var(--color-background-subtle-red);
  }
}

.color-button[data-tone='green'] {
  color: var(--color-text-darken-cool-green);
  background-color: var(--color-background-muted-cool-green);
  border-color: var(--color-stroke-subtle-cool-green);

  &:hover {
    border-color: var(--color-stroke-accent-cool-green);
    background-color: var(--color-background-subtle-cool-green);
  }

  &:focus,
  &:focus-within {
    border-color: var(--color-stroke-active);
    box-shadow: 0 0 0 1px var(--color-stroke-active) inset;
  }

  @include active() {
    border-color: var(--color-background-subtle-cool-green);
    background-color: var(--color-background-subtle-cool-green);
  }
}

.color-button[data-tone='green-alt'] {
  color: var(--color-text-darken-warm-mint);
  background-color: var(--color-background-muted-warm-mint);
  border-color: var(--color-stroke-subtle-warm-mint);

  &:hover {
    border-color: var(--color-stroke-accent-warm-mint);
    background-color: var(--color-background-subtle-warm-mint);
  }

  &:focus,
  &:focus-within {
    border-color: var(--color-stroke-active);
    box-shadow: 0 0 0 1px var(--color-stroke-active) inset;
  }

  @include active() {
    border-color: var(--color-background-subtle-warm-mint);
    background-color: var(--color-background-subtle-warm-mint);
  }
}

.color-button[data-tone='purple'] {
  color: var(--color-text-darken-purple);
  background-color: var(--color-background-muted-purple);
  border-color: var(--color-stroke-subtle-purple);

  &:hover {
    border-color: var(--color-stroke-accent-purple);
    background-color: var(--color-background-subtle-purple);
  }

  &:focus,
  &:focus-within {
    border-color: var(--color-stroke-active);
    box-shadow: 0 0 0 1px var(--color-stroke-active) inset;
  }

  @include active() {
    background-color: var(--color-background-subtle-purple);
    border-color: var(--color-background-subtle-purple);
  }
}

.color-button[data-tone='pink'] {
  color: var(--color-text-darken-cool-pink);
  background-color: var(--color-background-muted-cool-pink);
  border-color: var(--color-stroke-subtle-cool-pink);

  &:hover {
    border-color: var(--color-stroke-accent-cool-pink);
    background-color: var(--color-background-subtle-cool-pink);
  }

  &:focus,
  &:focus-within {
    border-color: var(--color-stroke-active);
    box-shadow: 0 0 0 1px var(--color-stroke-active) inset;
  }

  @include active() {
    background-color: var(--color-background-subtle-cool-pink);
    border-color: var(--color-background-subtle-cool-pink);
  }
}

.color-button[data-tone='violet'] {
  color: var(--color-text-darken-warm-pink);
  background-color: var(--color-background-muted-warm-pink);
  border-color: var(--color-stroke-subtle-warm-pink);

  &:hover {
    border-color: var(--color-stroke-accent-warm-pink);
    background-color: var(--color-background-subtle-warm-pink);
  }

  &:focus,
  &:focus-within {
    border-color: var(--color-stroke-active);
    box-shadow: 0 0 0 1px var(--color-stroke-active) inset;
  }

  @include active() {
    background-color: var(--color-background-subtle-warm-pink);
    border-color: var(--color-background-subtle-warm-pink);
  }
}
