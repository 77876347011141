@use '../../styles/yr' as *;

.sea-current {
  color: var(--color-text-base);
  display: inline-flex;
  white-space: pre-wrap;
}

.sea-current__arrow-icon {
  display: block;
}
