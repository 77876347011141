@use '../../styles/yr' as *;

.coast-header {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: 'header view .';

  margin-top: size(2);
  margin-bottom: size(0.5);
  @include mq-gt($mq-990) {
    margin-top: size(4);
    margin-bottom: size(2);
  }

  @include mq-lt($mq-990) {
    display: flex;
    flex-direction: column;
  }
}

.coast-header__title-container {
  display: flex;
  flex-direction: column;
  grid-area: header;
}

.coast-header__view-switcher {
  grid-area: view;

  @include mq-lt($mq-990) {
    margin-top: size(2);
  }
}

.coast-header__title {
  display: flex;
  align-items: center;
}

.coast-header__title-icon {
  margin-left: size(1);
}

.coast-header__button {
  align-self: center;
}
