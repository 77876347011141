@use '../../styles/yr' as *;

.coast-footer {
  display: flex;
  padding: size(2);
  justify-content: space-between;
  flex-direction: column;
  gap: size(1);
}

.coast-footer__wave-warning-link {
  position: relative;
}

.coast-footer__wave-warning-link-text {
  white-space: nowrap;
  padding-right: size(2);
}

.coast-footer__wave-warning-icon {
  position: absolute;
  top: 50%;
  right: size(-0.5);
  transform: translateY(-50%);
}
