@use '../../styles/yr' as *;

.coast-forecast-hourly-dialog__wind-description {
  @include mq-lte($mq-675) {
    display: none;
  }
}

.coast-forecast-hourly-dialog__symbol > .weather-symbol {
  height: size(4);
  width: size(4);
}
