@use '../../styles/yr' as *;

.empty-state {
  margin: 6rem auto;
  max-width: 50rem;
  min-height: 15rem;
  text-align: center;

  // Reduce vertical margin on shorter viewports
  @media screen and (max-height: rem(1000)) {
    margin: 5rem auto;
  }

  @media screen and (max-height: rem(800)) {
    margin: 4rem auto;
  }

  @media screen and (max-height: rem(600)) {
    margin: 3rem auto;
  }
}

.empty-state p {
  margin: 0;
  max-width: none;
}
